const path = {
  home: '/',
  detailArtist: '/artist/:nameId',
  editAlbum: '/artist/edit-album/:nameId',
  artistSongs: '/artist/:nameId/songs',
  detailAlbum: '/album/:nameId',
  detailPlaylist: '/playlist/:nameId',
  forgotPassword: '/forgotPassword',
  myPlaylist: '/myPlaylist',
  myAlbum: '/artist/myAlbum',
  myLibrary: '/my-library',
  chart: '/chart',
  dashboard: '/',
  user: '/user',
  search: '/search/',
  profile: '/profile',
  changePassword: '/change-password',
  historyTransaction: '/user/transaction-history',
  jobRequest: '/user/job-request',
  login: '/login',
  register: '/register',
  logout: '/logout',
  productDetail: ':nameId',
  cart: '/cart',
  expertDetail: '/expertDetail/:nameId',
  validateEmail: '/validate-email/:token',
  promoteToArtist: '/promote-to-artist',
  paymentResponse: '/payment-response',
  becomeArtist: '/user/become-artist',
  buyPremium: '/buy-premium',
  newRelease: '/new-release',

  //Artist
  artist: '/artist',
  artistProfile: '/profile',
  artistChangePassword: '/artist/changepassword',
  artistTransactionHistory: '/artist/transaction-history',
  artistAnalytics: '/artist/analytics',
  artistBookings: '/artist/bookings',
  artistShowListPost: '/artist/list-post',
  artistDashboard: '/artist/dashboard',
  artistWithdraw: '/artist/withdraw',

  //Admin
  admin: '/admin',
  adminProfile: '/admin/profile',
  adminListUser: '/admin/users-management',
  adminListMajor: '/admin/major-management',
  adminVerifyExpert: '/admin/verify-expert',
  adminListDocument: '/admin/document-management',
  adminListTransaction: '/admin/transaction-management',
  adminListReport: '/admin/report-management',
  adminDashBoard: '/admin/dashboard',
  adminWithdraw: '/admin/withdraw-management',
  adminMusic: '/admin/music-management',
  adminGenre: '/admin/genre-management',
  adminRequest: '/admin/request-management',
  adminAlbum: '/admin/album-management',
  adminPlaylist: '/admin/playlist-management',
  adminRegion: '/admin/region-management',
  adminPremiumPackage: '/admin/premium-package-management',
  adminSection: '/admin/section-management',
  adminCreateSection: '/admin/create-section',
  adminEditAlbum: '/admin/edit-album/:nameId'
}

export default path
